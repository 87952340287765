import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

import HeadingBack from "../../HeadingBack";
// import { useRouter } from "next/router";
// import { URL } from "@/Redux/common/url";
// import { allapiAction } from "@/Redux/common/action";
import SvgIcon from "../../const/SvgIcon";
import { Image } from "react-bootstrap";
import { allapiAction } from "../../../Redux/common/action";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { URL } from "../../../Redux/common/url";
import { toast } from "react-toastify";
import { AsyncPaginate } from "react-select-async-paginate";

function AddReportAndPlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  // const pagev = navigate?.query?.page;
  // const searchData = navigate?.query?.search;

  const { confirm } = Modal;
  console.log(id);
  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const get_product_list = useSelector((state) =>
    state?.allapi?.get_listing_list ? state?.allapi?.get_listing_list : {}
  );
  const get_seller_all_list = useSelector((state) =>
    state?.allapi?.get_seller_all_list ? state?.allapi?.get_seller_all_list : {}
  );

  console.log(get_seller_all_list);

  const [pages, setPage] = useState(1);

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];

  useEffect(() => {
    // if (search) {
    //   dispatch(allapiAction.searchListing({ page: page, key: search }));
    // } else {

    // }
    detdata();
    return () => {};
  }, [location.search]);

  const [first, setfirst] = useState({});
  const [productName, setproductName] = useState("");
  const [saledata, setsaledata] = useState({ amount: 0, commission: 0 });

  console.log(first);
  const [userselete, setuserselete] = useState(null);
  const hendletochangeamount = (e) => {
    const amount = e.target.value;

    const commpre = first?.totalSale >= 3000 ? 0.015 : 0.05;
    const comm = amount * commpre;

    setsaledata({
      amount,
      commission: comm,
    });
  };
  const detdata = async () => {
    const response = await dispatch(
      allapiAction.userDetailsbyvendorget({ _id: id })
    );

    setfirst(response?.data);
  };
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    getLocation();
    return () => {};
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [statusData1, setstatusData1] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBox2, setstatusDataBox2] = useState(false);
  const [statusDataBox1, setstatusDataBox1] = useState(false);
  const [ListProduct, setListProduct] = useState([]);
  const [userselete2, setuserselete2] = useState(null);
  const [ListParty, setListParty] = useState([
    { party: null, discription: "" },
  ]);
  console.log(ListParty);

  const handleColorChange = (rowIndex, field, value) => {
    setListParty((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex][field] = value;
      return updatedRows;
    });
  };
  const addParty = () => {
    setListParty([...ListParty, { party: null, discription: "" }]);
  };

  const removeParty = (e) => {
    setListParty((prevRows) => prevRows.filter((row, i) => i !== e));
    // setListParty([...ListParty, { party: null, discription: "" }]);
  };

  const loadOptionsPalyer2 = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const token = await localStorage.getItem("access_token_vendor");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      URL.API_BASE_URL +
        `/vendorApi/searchingUserForSallVendor?key=${search}&page=${pages?.page}`,
      config
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.fullName + ", " + data?.referId;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const [stateselect, setstateselect] = useState(null);
  const [districtselect, setdistrictselect] = useState(null);
  const [cityselect, setcityselect] = useState(null);

  const [mangeKey, setmangeKey] = useState(0);
  const [mangeKey2, setmangeKey2] = useState(0);

  useEffect(() => {
    setmangeKey(mangeKey + 1);
    setmangeKey2(mangeKey2 + 1);
    setdistrictselect(null);
    setcityselect(null);

    return () => {};
  }, [stateselect]);
  useEffect(() => {
    // setmangeKey(mangeKey + 1);
    setmangeKey2(mangeKey2 + 1);
    setcityselect(null);
    return () => {};
  }, [districtselect]);

  const loadOptionsState = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaStates?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.State;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionsDisrcit = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaDistricts?key=${search}&page=${pages?.page}&States_id=${stateselect?.State}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.District;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const loadOptionsCity = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareacity?key=${search}&page=${pages?.page}&District_id=${districtselect?.District}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.Area + ", " + data?.Pincode;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const hendletoCompleteSubmitOrder = async () => {
    //

    if (latitude == "") {
      toast?.error("please allow location");
      return;
    }
    // if (!userselete2) {
    //   toast?.error("please select user");
    //   return;
    // }
    if (!statusData?.discription) {
      toast?.error("please discription");
      return;
    }

    const userdeatisl = {
      discription: statusData?.discription,
      type: statusData?.type,

      // userIdnew: ,a

      latitude: latitude,
      longitude: longitude,
      // user_name: get_seller_all_list?.userDetails?.fullName,
    };
    if (userselete2?._id) {
      userdeatisl.userIdnew = userselete2?._id;
    }
    if (ListParty?.length > 0 && statusData?.type == "Plan") {
      const resp = ListParty?.map((item) => {
        return {
          party: {
            _id: item?.party?._id,
            fullName: item?.party?.fullName,
            referId: item?.party?.referId,
            phone: item?.party?.phone,
          },
          discription: item?.discription,
        };
      });
      userdeatisl.discriptionpartyWise = JSON.stringify(resp);
    }
    const response = await dispatch(allapiAction.visitAndPlan(userdeatisl));
    if (response?.success) {
      setstatusDataBox1(false);
      setstatusDataBox(false);
      navigate("/account-sales-man");
      // setfirst(response?.data);
      // setproductName("");
      // setsaledata({ amount: 0, commission: 0 });
    }
  };
  const hendletoCompleteSubmitAddUser = async () => {
    //

    // if (latitude == "") {
    //   toast?.error("please allow location");
    //   return;
    // }
    // // if (!userselete2) {
    // //   toast?.error("please select user");
    // //   return;
    // // }
    // if (!statusData?.discription) {
    //   toast?.error("please discription");
    //   return;
    // }

    const userdeatisl = {
      fullName: statusData1?.fullName,
      phone: statusData1?.phone,
      password: statusData1?.password,
      district: districtselect?.District,
      city: cityselect?.Area,
      state: stateselect?.State,
      zip: cityselect?.Pincode,
      // zip: cityselect?.Pincode,
      location_id: cityselect?._id,
      country: statusData1?.country,
      email: statusData1?.email,
      // side: statusData1?.side,
      displayName: statusData1?.displayName,
    };

    const response = await dispatch(
      allapiAction.addPartyBySalesman(userdeatisl)
    );
    if (response?.success) {
      setstatusDataBox2(false);
      setstatusDataBox(false);
      // navigate("/account-sales-man");
      // setfirst(response?.data);
      // setproductName("");
      // setsaledata({ amount: 0, commission: 0 });
    }
  };
  const hendletoSubmitOrder = async () => {
    //

    if (latitude == "") {
      toast?.error("please select location");
      return;
    }
    if (!userselete2) {
      toast?.error("please select user");
      return;
    }
    setstatusDataBox1(true);
  };

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  console.log(ListProduct);

  const totalAmount = ListProduct?.reduce((total, obj) => {
    return total + obj?.afterdiscountprice * obj?.item;
  }, 0);
  const totalAmountItme = ListProduct?.reduce((total, obj) => {
    return total + parseInt(obj?.item);
  }, 0);

  const removebyindex = (e) => {
    const stdata = [...ListProduct];
    stdata.splice(e, 1);
    setListProduct(stdata);
  };

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };
  const hendleToViewMNewParty = (e) => {
    setstatusDataBox2(true);
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };
  const hendleTochangedata1 = (e) => {
    const { name, value } = e.target;

    setstatusData1({ ...statusData1, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    if (!statusData.item) {
      toast?.error("please enter quantity");
      return;
    }
    const stdata = [...ListProduct];
    stdata.push({ ...userselete, item: statusData.item });
    setListProduct(stdata);
    setTimeout(() => {
      setuserselete(null);
    }, 300);

    setstatusDataBox(false);
    setstatusData({ item: "" });
  };

  const showModal = (e) => {
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const hendleToStatusfuture = (id, e) => {
    // statusCategory

    dispatch(
      allapiAction.futuredListing({ productId: id, status: e, pageNo: page })
    );
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <div style={{ width: "100px" }}>
              <img
                style={{ width: "100%" }}
                src={URL?.API_BASE_URL + item?.productcolorId?.featureImage}
                alt=""
              />
            </div>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.productId?.title}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "After Discount Price",
      dataIndex: "afterdiscountprice",
      key: "afterdiscountprice",
    },
    {
      title: "itme count",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Total Price",
      dataIndex: "totalprice",
      key: "totalprice",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.afterdiscountprice * item?.item}
            </span>
          </>
        );
      },
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              {/* <Link
                className="cursor-pointer"
                to={`/seller/listing/update-listing/${item?.pro_sulg}`}
                // onClick={() =>
                //   // navigate(
                //   //   `/seller/listing/update-listing/${item?.pro_sulg}`
                //   // )

                //   (window.location.href = `/seller/listing/update-listing/${item?.pro_sulg}`)
                // }
              >
                <Image
                  src={SvgIcon?.Pencil}
                  alt="Left Arrow"
                  width={20}
                  height={20}
                  style={{ verticalAlign: "middle" }}
                />
                
              </Link> */}
            </Tooltip>
            {/* <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip> */}
            {/* <Tooltip title="Add Point">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Add Point
              </button>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  removebyindex(index);
                }}
              >
                <Image
                  src={SvgIcon?.deletebtn}
                  alt="Left Arrow"
                  width={20}
                  height={20}
                  style={{ verticalAlign: "middle" }}
                />
                {/* <SvgIcon.deletebtn className="scale-icon edit-icon" /> */}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/listing?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    navigate("/seller/listing?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/productGetforsalingbysalesman?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label =
        data?.productId?.title +
        "-" +
        data?.productcolorId?.color +
        "-" +
        data?.size;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  return (
    <div className="container-fluid">
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add New Party"
        centered
        open={statusDataBox2}
        onOk={() => hendletoCompleteSubmitAddUser(false)}
        onCancel={() => setstatusDataBox2(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Full Name </Col>
            <Col span={24}>
              <Input
                name="fullName"
                placeholder="enter fullName"
                value={statusData?.fullName}
                onChange={(e) => {
                  hendleTochangedata1(e);
                }}
              />
            </Col>
            <Col span={24}>Shop Name </Col>
            <Col span={24}>
              <Input
                name="displayName"
                placeholder="enter shop Name"
                value={statusData?.displayName}
                onChange={(e) => {
                  hendleTochangedata1(e);
                }}
              />
            </Col>
            <Col span={24}>Email</Col>
            <Col span={24}>
              <Input
                name="email"
                placeholder="enter email"
                value={statusData?.email}
                onChange={(e) => {
                  hendleTochangedata1(e);
                }}
              />
            </Col>
            <Col span={24}>Phone</Col>
            <Col span={24}>
              <Input
                name="phone"
                placeholder="enter phone"
                value={statusData?.phone}
                onChange={(e) => {
                  hendleTochangedata1(e);
                }}
              />
            </Col>
            {/* <Col span={24}>Phone</Col>
            <Col span={24}>
              <Input
                name="phone"
                placeholder="enter phone"
                value={statusData?.phone}
                onChange={(e) => {
                  hendleTochangedata1(e);
                }}
              />
            </Col> */}
            <Col span={24}>Password</Col>
            <Col span={24}>
              <Input
                name="password"
                placeholder="enter password"
                value={statusData?.password}
                onChange={(e) => {
                  hendleTochangedata1(e);
                }}
              />
            </Col>
            <Col span={24}>State</Col>
            <Col span={24}>
              <AsyncPaginate
                value={stateselect}
                className="liceninput"
                placeholder="State"
                loadOptions={loadOptionsState}
                onChange={setstateselect}
                additional={{
                  page: 1,
                }}
              />
            </Col>
            <Col span={24}>District</Col>
            <Col span={24}>
              <AsyncPaginate
                key={mangeKey}
                value={districtselect}
                className="liceninput"
                placeholder="District"
                loadOptions={loadOptionsDisrcit}
                onChange={setdistrictselect}
                additional={{
                  page: 1,
                }}
              />
            </Col>
            <Col span={24}>Postal Area</Col>
            <Col span={24}>
              <AsyncPaginate
                key={mangeKey2}
                value={cityselect}
                className="liceninput"
                placeholder="City"
                loadOptions={loadOptionsCity}
                onChange={setcityselect}
                additional={{
                  page: 1,
                }}
              />
            </Col>

            {/* <Col span={24}>Discription</Col>
            <Col span={24}>
              <Input
                name="discription"
                placeholder="enter discription"
                value={statusData?.discription}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col> */}
          </Row>
        </div>
      </Modal>
      <Modal
        title="Add Report"
        centered
        open={statusDataBox}
        onOk={() => hendletoCompleteSubmitOrder(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Select plan </Col>
            <Col span={24}>
              {/* <Input
                name="discription"
                placeholder="enter discription"
                value={statusData?.discription}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              /> */}

              <select
                className="form-control"
                onChange={(e) => hendleTochangedata(e)}
                name="type"
              >
                <option value={""}>Select </option>
                <option value={"Plan"}>Plan</option>
                <option value={"Collect Payment"}>Collect Payment</option>
                <option value={"Order"}>Order</option>
                <option value={"Field Visit"}>Field Visit</option>
                <option value={"New Distributor"}>New Distributor</option>
                <option value={"Visit Distributor"}>Visit Distributor</option>
                <option value={"Report"}>Report </option>
                <option value={"Others"}>Others </option>
              </select>
            </Col>
            {/* <Col span={24}>Enter Shop name</Col>
            <Col span={24}>
              <Input
                name="shopName"
                placeholder="enter shop name"
                value={statusData?.shopName}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />

               
            </Col> */}
            {statusData?.type !== "Plan" && <Col span={24}>Select User</Col>}
            {statusData?.type !== "Plan" && (
              <Col span={24}>
                <AsyncPaginate
                  value={userselete2}
                  className="liceninputv2"
                  // placeholder="location"
                  placeholder="User"
                  loadOptions={loadOptionsPalyer2}
                  onChange={setuserselete2}
                  additional={{
                    page: 1,
                  }}
                />
              </Col>
            )}
            {statusData?.type === "Plan" && <Col span={24}>Select Party </Col>}
            {statusData?.type === "Plan" && (
              <Col span={24}>
                {/* <AsyncPaginate
                value={userselete2}
                className="liceninputv2"
                // placeholder="location"
                placeholder="User"
                loadOptions={loadOptionsPalyer2}
                onChange={setuserselete2}
                additional={{
                  page: 1,
                }}
              /> */}
                <div>
                  {ListParty &&
                    ListParty?.map((data, i) => {
                      return (
                        <div className="mb-3">
                          <Row>
                            <Col span={20}>
                              {" "}
                              <AsyncPaginate
                                // value={userselete2}
                                value={data.party}
                                className="liceninputv2"
                                // placeholder="location"
                                placeholder="User"
                                loadOptions={loadOptionsPalyer2}
                                // onChange={setuserselete2}
                                onChange={(e) =>
                                  handleColorChange(
                                    i,

                                    "party",
                                    e
                                  )
                                }
                                additional={{
                                  page: 1,
                                }}
                              />
                            </Col>
                            <Col span={4} className="text-right mt-10">
                              <Button
                                type="primary"
                                danger
                                onClick={() => removeParty(i)}
                              >
                                <DeleteOutlined />
                              </Button>
                              {/* <ExclamationCircleFilled className="text-danger" style={{ fontSize: '20px' }} onClick={() => removeParty(i)} /> */}
                            </Col>
                            <Col span={20}>
                              <Input
                                name="discription"
                                placeholder="enter discription"
                                // value={statusData?.discription}
                                value={data.discription}
                                onChange={(e) =>
                                  handleColorChange(
                                    i,

                                    "discription",
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                            <Col span={24}>
                              <hr />
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                </div>

                <button className="btn btn-primary" onClick={addParty}>
                  Add Party
                </button>
              </Col>
            )}
            <Col span={24}>Discription</Col>
            <Col span={24}>
              <Input
                name="discription"
                placeholder="enter discription"
                value={statusData?.discription}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="Pay | Advance Amount"
        centered
        open={statusDataBox1}
        onOk={() => hendletoCompleteSubmitOrder(false)}
        onCancel={() => setstatusDataBox1(false)}
        width={500}
      >
        <div>
          <Row>
            {/* <Col span={24}>Select Product</Col>
            <Col span={24}>
              <AsyncPaginate
                value={userselete}
                className="liceninputv2"
                // placeholder="location"
                placeholder="Product"
                loadOptions={loadOptionsPalyer}
                onChange={setuserselete}
                additional={{
                  page: 1,
                }}
              />
            </Col> */}
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="advanceamount"
                placeholder="enter amount"
                value={statusData?.advanceamount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>Payment type</Col>
            <Col span={24}>
              <Input
                name="paymenttype"
                placeholder="enter payment type"
                value={statusData?.paymenttype}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>Transaction id</Col>
            <Col span={24}>
              <Input
                name="transactionid"
                placeholder="enter transactionid"
                value={statusData?.transactionid}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>

            <Col span={24}>Discription</Col>
            <Col span={24}>
              <Input
                name="descriptionamount"
                placeholder="enter discription"
                value={statusData?.descriptionamount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Plan And Reports"}
            // subHeading={`Total ${get_product_list?.totalDocs || 0} Listing`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          {/* <Tooltip title="Add">
            <span
              className="jop_btn"
              onClick={
                () => navigate("/seller/listing/add-listing")
                // (window.location.href = "/seller/listing/add-listing")
              }
            >
              Add
               
            </span>
          </Tooltip> */}
        </div>
      </div>

      {/* <div>
        <label>Select User</label>
        <AsyncPaginate
          value={userselete2}
          className="liceninputv2"
          // placeholder="location"
          placeholder="User"
          loadOptions={loadOptionsPalyer2}
          onChange={setuserselete2}
          additional={{
            page: 1,
          }}
        />
      </div> */}

      <div className="tableResponsive mt-10">
        {/* <Table
          className={"table antd_table"}
          dataSource={ListProduct}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 500000,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: get_product_list?.totalDocs
              ? get_product_list?.totalDocs
              : 0,
          }}
        /> */}

        <div className="container">
          <div className="row">
            {/* <div className="col-12">Total Amount : {totalAmount}</div>
            <div className="col-12">Total itme : {totalAmountItme}</div> */}
            <div className="col-sm-4"></div>
            <div className="col-sm-4 mt-60">
              <button
                className="btn btn-primary"
                onClick={() => {
                  hendleToViewM();
                }}
              >
                Add Report And Plans
              </button>
              <button
                className="btn btn-primary ml-5"
                onClick={() => {
                  hendleToViewMNewParty();
                }}
              >
                Add New Party
              </button>
              {/* {ListProduct?.length > 0 && (
                <button
                  className="btn btn-primary ml-10"
                  onClick={() => {
                    hendletoSubmitOrder();
                  }}
                >
                  Order Complete
                </button>
              )} */}
            </div>
            <div className="col-sm-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddReportAndPlan;
